import React, {useContext, useEffect, useState} from 'react';
import {VulcainSnackbar} from '@vestiaire/vc-vulcain-component-library';

import {displaySnackbarMsg, resetSnackbar} from 'store/GlobalAction';
import {GlobalContext} from 'store/GlobalContext';

import './Snackbar.scss';
import SnackbarStatus from "utils/SnackbarStatus";

const SnackbarListener: React.FC = () => {
  // GLOBAL STORE
  const {
    state: {snackbarMsg, snackbarStatus},
    dispatch,
  } = useContext(GlobalContext);

  // LOCAL STATE
  const [isSnackbarOpened, setIsSnackbarOpened] = useState(false);

  // EFFECTS
  useEffect(() => {
    if (snackbarMsg) {
      // open snackbar
      setIsSnackbarOpened(true);
    }
  }, [snackbarMsg]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('snackbar', dispatchSnackbarMsg);
    return () => {
      // @ts-ignore
      window.removeEventListener('snackbar', dispatchSnackbarMsg);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchSnackbarMsg = (evt: CustomEvent<{ status: string, message: string }>) => {
    evt.stopPropagation();
    dispatch(displaySnackbarMsg(evt?.detail?.message, evt?.detail?.status as SnackbarStatus));
  }

  const closeSnackbar = () => {
    setIsSnackbarOpened(false);
    dispatch(resetSnackbar());
  }

  return (
    <div id="snackbar">
      <VulcainSnackbar
        status={snackbarStatus as string}
        message={snackbarMsg as string}
        opened={isSnackbarOpened}
        onFinished={closeSnackbar}
      />
    </div>
  );
}

export default SnackbarListener;
