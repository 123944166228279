import i18n from 'i18n';

import { getUserLang, getUserTheme } from 'utils/user';
import SnackbarStatus from 'utils/SnackbarStatus';

export interface GlobalState {
  pageTitle: string;
  showHeader: boolean;
  snackbarMsg: string | null;
  snackbarStatus: SnackbarStatus | null;
  userLang: string;
  userTheme: string;
}

export const initialGlobalState: GlobalState = {
  pageTitle: i18n.t('TITLE'),
  showHeader: true,
  snackbarMsg: null,
  snackbarStatus: null,
  userLang: getUserLang(),
  userTheme: getUserTheme(),
};
