import jwt_decode from 'jwt-decode';
import PKCE from 'js-pkce';
import Cookies from 'cookies-ts';

import {StateService} from './state.service';
import IAuthResponse, {IdToken} from './types';
import getEnvironment from '../environments';
import {IEnvironment} from '../environments/model';

export const LOCAL_STORAGE_USER_LOCATION = 'user_location';

export class AuthenticationService {

  private readonly COOKIE_ACCESS_TOKEN = 'access_token';
  private readonly COOKIE_ID_TOKEN = 'id_token';

  private readonly _state: StateService;
  private readonly _auth: PKCE;
  private readonly _env: IEnvironment;

  private readonly _accessToken: string | null;
  private readonly _idToken: IdToken | null = null;
  private readonly _cookies: Cookies

  constructor() {
    this._cookies = new Cookies();
    this._accessToken = this._cookies.get(this.COOKIE_ACCESS_TOKEN);
    this._env = getEnvironment();

    const id = this._cookies.get(this.COOKIE_ID_TOKEN);

    if (id) {
      try {
        this._idToken = jwt_decode<IdToken>(id);
      } catch (e) {
        this.logout();
      }
    }

    this._state = new StateService(this._env);
    this._auth = new PKCE({
      client_id: this._env.oauthClientId,
      redirect_uri: this._env.oauthClientCallbackUrl,
      authorization_endpoint: `${this._env.oauthUrl}/oauth2/auth`,
      token_endpoint: `${this._env.oauthUrl}/oauth2/token`,
      requested_scopes: this._env.oauthClientScopes,
    });
  }

  logout() {
    localStorage.clear();
    this._cookies.remove(this.COOKIE_ID_TOKEN, {
      domain: this._env.cookiesDomain,
    });
    this._cookies.remove(this.COOKIE_ACCESS_TOKEN, {
      domain: this._env.cookiesDomain,
    });
  }

  getUser(): IdToken | null {
    return this._idToken;
  }

  getAccessToken(): string | null {
    return this._accessToken;
  }

  getIdToken(): IdToken | null {
    return this._idToken;
  }

  authorize() {
    window.location.replace(
      this._auth.authorizeUrl({
        state: this._state.persist().toString()
      })
    );
  }

  async callback() {
    return new Promise<void>(async (resolve, reject) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const remoteState = urlSearchParams.get('state');

      if (remoteState === null) {
        return reject('State parameter not found');
      }

      if (['teod', 'stage'].includes(this._env.env)) {
        const decodedState = this._state.decodeState(remoteState);
        if (
          decodedState.env &&
          ['vulcain.pages.dev', 'backoffice.staging.vestiairecollective.com'].includes(window.location.hostname)
        ) {
          window
            .location
            .replace(`https://${decodedState.env}.vulcain.pages.dev/auth/callback${window.location.search}`)
          ;
        }
      }

      if (!this._state.check(remoteState)) {
        return reject('Invalid state');
      }

      this._state.clear();
      const token = await this._auth.exchangeForAccessToken(window.location.toString()) as IAuthResponse

      localStorage.setItem(this.COOKIE_ACCESS_TOKEN, token.access_token);
      localStorage.setItem(this.COOKIE_ID_TOKEN, token.id_token);

      this._cookies.set(this.COOKIE_ACCESS_TOKEN, token.access_token, {
        expires: `${token.expires_in}s`,
        domain: this._env.cookiesDomain,
      });

      this._cookies.set(this.COOKIE_ID_TOKEN, token.id_token, {
        expires: `${token.expires_in}s`,
        domain: this._env.cookiesDomain,
      });

      resolve();
    })
  }
}
