export function safeText(text: string): string {
  return text.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
  .replace(/(?:\r\n|\r|\n)/g, ' ')
  .replace(/\\/g, '');
}

export function formatText(text: string): { __html: string } {
  const html = text.replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/\\/g, '');
  return {__html: html};
}
