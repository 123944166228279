import { IEnvironmentConfig } from './model';

const stageEnvironment: IEnvironmentConfig = {
  env: 'stage',
  logLevel: 'debug',
  apiBaseUrl: 'https://papi.staging.vestiairecollective.com',
  atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
  imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
  cookiesDomain: '.backoffice.staging.vestiairecollective.com',
  oauthUrl: 'https://boauth.preprod.vestiairecollective.com',
  oauthClientCallbackUrl: 'https://backoffice.staging.vestiairecollective.com/auth/callback',
  oauthClientId: 'vc-backoffice',
  oauthClientScopes: 'openid email profile',
  toolAuth: 'https://authentication.backoffice.staging.vestiairecollective.com',
};
export default stageEnvironment;
