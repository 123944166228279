import { IEnvironmentConfig } from './model';

const devEnvironment: IEnvironmentConfig = {
  env: 'dev',
  logLevel: 'debug',
  apiBaseUrl: 'https://papi.staging.vestiairecollective.com',
  atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
  imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
  cookiesDomain: 'localhost',
  oauthUrl: 'https://boauth.preprod.vestiairecollective.com',
  oauthClientCallbackUrl: 'http://localhost:3000/auth/callback',
  oauthClientId: 'vc-backoffice',
  oauthClientScopes: 'openid email profile',
  toolAuth: 'https://authentication.backoffice.staging.vestiairecollective.com',
};

export default devEnvironment;
