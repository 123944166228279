import i18n from 'i18n';
import { useContext, useEffect } from 'react';
import { changePageTitle } from 'store/GlobalAction';
import { GlobalContext } from 'store/GlobalContext';
import { safeText } from 'utils/helpers';

export default function useTitle(title: string, dispatchInPageTitle: boolean = false) {
  const { dispatch } = useContext(GlobalContext);
  useEffect(() => {
    const prevTitle = i18n.t('TITLE');
    document.title = safeText(title);
    if (dispatchInPageTitle) {
      dispatch(changePageTitle(title));
    }
    return () => {
      document.title = safeText(prevTitle);
      if (dispatchInPageTitle) {
        dispatch(changePageTitle(prevTitle));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
