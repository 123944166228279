interface IProps {
  size: number;
}

const CampaignManagementIcon = ({ size }: IProps): JSX.Element => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 512 512" 
      width={size} 
      height={size}
      style={{
        padding: '10px',
        background:'#ebecec',
        borderRadius: '5px'
      }}
    >
      <path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32z"/>
      <path fill="#ff5a27" d="M480 96c0-17.7-14.3-32-32-32s-32 14.3-32 32V320c0 17.7 14.3 32 32 32s32-14.3 32-32V96zM288 160c0-17.7-14.3-32-32-32s-32 14.3-32 32V320c0 17.7 14.3 32 32 32s32-14.3 32-32V160zm-96 96c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7 14.3 32 32 32s32-14.3 32-32V256zm160-64c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V224c0-17.7-14.3-32-32-32z"/>
    </svg>
  );
};

export default CampaignManagementIcon;