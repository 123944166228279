import React from 'react';
import { Link } from 'react-router-dom';
import { i18n } from 'i18next';

import { formatText } from 'utils/helpers';
import { createIcon, IMenuItem } from 'menu';

const MenuLink = (item: IMenuItem, i18n: i18n) => {
  const content = (
    <div className="navigation__app__item">
      {createIcon(item.icon, 48)}
      <div
        className="vc-text-xs navigation__app__item--title"
        dangerouslySetInnerHTML={formatText(i18n.t(`TOOLS.${item.key}.TITLE`))}
      />
    </div>
  );

  if (item.link.startsWith('http')) {
    return (
      <a
        rel="noreferrer"
        href={item.link}
        data-cy={`head-link-tool-${item.key}`}
      >
        {content}
      </a>
    );
  }

  return (
    <Link
      to={item.link}
      data-cy={`head-link-tool-${item.key}`}
    >
      {content}
    </Link>
  );
};

export default MenuLink;
