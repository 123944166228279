import Cookies from 'cookies-ts';
import getEnvironment from "environments";

const $cookies = new Cookies();

export function getUserLang(): string {
  return $cookies.isKey(getEnvironment().i18n.i18nCookie) ?
    $cookies.get(getEnvironment().i18n.i18nCookie) as string :
    getEnvironment().i18n.locale as string;
}

export function setUserLang(value: string) {
  $cookies.set(
    getEnvironment().i18n.i18nCookie,
    value,
    {
      domain: getEnvironment().cookiesDomain,
      expires: Infinity
    }
  );
}

export function getUserTheme(): string {
  return $cookies.isKey(getEnvironment().theme.themeCookie) ?
    $cookies.get(getEnvironment().theme.themeCookie) as string :
    getEnvironment().theme.default as string;
}

export function setUserTheme(value: string) {
  $cookies.set(
    getEnvironment().theme.themeCookie,
    value,
    {
      domain: getEnvironment().cookiesDomain,
      expires: Infinity
    }
  );
}
