import {AuthenticationService} from "../services/authentication.service";
import {IdToken} from "../services/types";

type Auth = {
  auth: AuthenticationService,
  accessToken: string | null,
  idToken: IdToken | null
}

export function useAuthentication(): Auth {
  const auth = new AuthenticationService();
  return {
    auth,
    accessToken: auth.getAccessToken(),
    idToken: auth.getIdToken()
  };
}



