import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VulcainButton } from '@vestiaire/vc-vulcain-component-library';

import ROUTES from 'utils/ROUTES';

import './Menu.scss';
import { useAuthentication } from 'utils/useAuthentication';

const Menu: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const { idToken } = useAuthentication();

  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => {
    setIsOpen(false);
    document.removeEventListener('click', closeMenu);
  };

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        document.addEventListener('click', closeMenu);
      }, 0);
    }
  };

  return (
    <div className="dropdown__menu">
      <VulcainButton
        className="dropdown__menu--button"
        label={idToken?.given_name}
        iconName={isOpen ? 'arrow-head-up' : 'arrow-head-down'}
        iconPosition="right"
        variant="ghost"
        onClick={handleMenuClick}
      />
      <div
        className={`dropdown__menu--items vc-text-m ${isOpen && 'visible'}`}
        aria-hidden={isOpen}
      >
        <div className="dropdown__menu--container">
          <div className="topbar--user-details">
            <p className="user-details--fullname">
              {idToken?.given_name} {idToken?.family_name}
            </p>
            <p className="user-details--email vc-text-s">{idToken?.email}</p>
          </div>
          <div className="topbar--items">
            {/* <Link className="topbar--item" to={ROUTES.RETURN}>{i18n.t("MENU.PARAMETERS")}</Link> */}
            <Link className="topbar--item" to={ROUTES.PREFERENCES}>
              {i18n.t('MENU.PREFERENCES')}
            </Link>
          </div>
          <div className="topbar--foot">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
