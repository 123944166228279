import {Base64} from "js-base64";
import {State} from "./types";
import {IEnvironment} from "../environments/model";

export class StateService {
  private readonly LOCAL_STORAGE_STATE = 'state';
  private readonly _env: string | null = null;
  private readonly _nonce: string;

  constructor(environment: IEnvironment) {

    if (environment.env === 'teod') {
      this._env = environment.teod || null;
    }

    this._nonce = window.crypto.randomUUID();
  }

  get(): State {
    return {
      env: this._env,
      nonce: this._nonce
    }
  }

  getNonce(): string {
    return this._nonce;
  }

  getEnv(): string | null {
    return this._env;
  }

  toString(): string {
    return Base64.btoa(JSON.stringify(this.get()))
  }

  persist(): StateService {
    localStorage.setItem(this.LOCAL_STORAGE_STATE, this.toString());
    return this;
  }

  check(remoteState: string): boolean {
    const localState = localStorage.getItem(this.LOCAL_STORAGE_STATE);

    if (localState === null) {
      return false;
    }

    return localState === remoteState;
  }

  clear(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_STATE);
  }

  decodeState(state: string): State {
    return JSON.parse(Base64.atob(state));
  }
}
