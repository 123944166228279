import { IEnvironmentConfig } from './model';

const productionEnvironment: IEnvironmentConfig = {
  env: 'production',
  logLevel: 'warn',
  apiBaseUrl: 'https://papi.vestiairecollective.com',
  atlasBaseUrl: 'https://apiv2.vestiairecollective.com',
  imagesBaseUrl: 'https://images.vestiairecollective.com',
  cookiesDomain: '.backoffice.vestiairecollective.com',
  oauthUrl: 'https://boauth.vestiairecollective.com',
  oauthClientCallbackUrl: 'https://backoffice.vestiairecollective.com/auth/callback',
  oauthClientId: 'vc-backoffice',
  oauthClientScopes: 'openid email profile',
  toolAuth: 'https://authentication.backoffice.vestiairecollective.com',
};

export default productionEnvironment;
