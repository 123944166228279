import React, { createContext, useReducer } from 'react';
import { ActionType } from './GlobalAction';
import { globalReducer } from './GlobalReducer';

import { GlobalState, initialGlobalState } from './GlobalState';

export type GlobalContextType = {
  state: GlobalState;
  dispatch: React.Dispatch<{ type: ActionType }>;
};

export const GlobalContext = createContext<GlobalContextType>({
  state: initialGlobalState,
  dispatch: () => null,
});

type Props = { children: React.ReactNode };

export const GlobalContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

  return (
    <>
      {/* @ts-ignore */}
      <GlobalContext.Provider value={{ state, dispatch }}>
        {children}
      </GlobalContext.Provider>
    </>
  );
};
