import React from 'react';

interface IProps {
    size: number;
}

const ShippingIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#ff5a27"
                  d="M440 32c-13.3 0-24 10.7-24 24V72c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H440zm0 160c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V216c0-13.3-10.7-24-24-24H440zM416 440v16c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V440c0-13.3-10.7-24-24-24H440c-13.3 0-24 10.7-24 24zm16-312c-8.8 0-16 7.2-16 16s7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432zM416 304c0 8.8 7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432c-8.8 0-16 7.2-16 16zm16 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432z"/>
            <path fill="#1e1e1e"
                  d="M112 32C50.1 32 0 82.1 0 144c0 50.7 33.8 93.6 80 107.4L8.1 374.7c-13.3 22.8-5.7 52.2 17.1 65.6l53.4 31.4c22.8 13.4 52.3 5.8 65.7-17L261.2 256H304c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H112z"/>
        </svg>
    );
};

export default ShippingIcon;
