import { IEnvironmentConfig } from './model';

const teodEnvironment: IEnvironmentConfig = {
  env: 'teod',
  logLevel: 'debug',
  apiBaseUrl: 'https://papi.staging.vestiairecollective.com',
  atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
  imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
  cookiesDomain: '.vulcain.pages.dev',
  oauthUrl: 'https://boauth.preprod.vestiairecollective.com',
  oauthClientCallbackUrl: 'https://backoffice.staging.vestiairecollective.com/auth/callback',
  oauthClientId: 'vc-backoffice',
  oauthClientScopes: 'openid email profile',
  toolAuth: 'https://authentication.backoffice.staging.vestiairecollective.com',
};

export default teodEnvironment;
