import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VulcainIcon } from '@vestiaire/vc-vulcain-component-library';
import { GlobalContext } from 'store/GlobalContext';
import { formatText } from 'utils/helpers';
import ROUTES from 'utils/ROUTES';

import MenuLink from 'components/molecules/Header/MenuLink';
import AuthenticationBtn from 'components/organisms/Authentication/AuthenticationBtn';

import { IMenuItem, menu, shouldRender } from 'menu';

import './Header.scss';
import { useAuthentication } from 'utils/useAuthentication';

const Header: React.FC = () => {
  const {
    state: { pageTitle },
  } = useContext(GlobalContext);

  const { accessToken, idToken } = useAuthentication();

  const [appsMenuOpen, setAppsMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  const expiresIn = idToken?.exp || -1;
  const HOUR_MS = 3600000;
  // const MINUTE_MS = 60000;

  useEffect(() => {
    if (expiresIn) {
      const interval = setInterval(() => {
        isExpired();
      }, HOUR_MS);

      isExpired();
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isExpired = () => {
    if (Math.trunc(Date.now() / 1000) >= expiresIn) {
      window.dispatchEvent(new CustomEvent('refreshToken'));
    }
  };

  const closeAppsMenu = () => {
    setAppsMenuOpen(false);
    document.removeEventListener('click', closeAppsMenu);
  };

  const handleAppsClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    setAppsMenuOpen(!appsMenuOpen);
    if (!appsMenuOpen) {
      setTimeout(() => {
        document.addEventListener('click', closeAppsMenu);
      }, 0);
    }
  };

  return (
    <header className="header">
      <div className="header__innerWrapper">
        <Link to={ROUTES.HOME} className="header__primary">
          <div className="header__logo">
            <VulcainIcon name="logo-v" />
          </div>
          <h1
            className="vc-text-m header__title"
            dangerouslySetInnerHTML={formatText(pageTitle)}
          />
        </Link>
        <nav className="header__navigation">
          <ul>
            {accessToken !== null && (
              <li className="header__navigation__item navigation__apps">
                <a
                  aria-label="Applications"
                  aria-expanded={appsMenuOpen}
                  role="button"
                  href="https://vestiairecollective.okta.com/app/UserHome"
                  className="header__navigation__item--link header__navigation__item--icon mix-kebad"
                  onClick={handleAppsClick}
                >
                  <VulcainIcon name="kebab-menu" />
                  <VulcainIcon name="kebab-menu" />
                  <VulcainIcon name="kebab-menu" />
                  <span className="vc-text-s">{i18n.t('MENU.APPS')}</span>
                </a>
                <div
                  className={`navigation__apps--overlay ${
                    appsMenuOpen && 'visible'
                  }`}
                  aria-hidden={appsMenuOpen}
                >
                  {menu.apps.map(
                    (item: IMenuItem, index: number) =>
                      shouldRender(item) && (
                        <Fragment key={item.key + index}>
                          {MenuLink(item, i18n)}
                        </Fragment>
                      )
                  )}
                </div>
              </li>
            )}
            <li>
              <AuthenticationBtn isAuthenticated={accessToken !== null} />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
