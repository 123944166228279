import React from 'react';

interface IProps {
    size: number;
}

const ReturnIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#ff5a27"
                  d="M256 0c17.7 0 32 14.3 32 32V210.7l41.4-41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-96 96c-12.5 12.5-32.8 12.5-45.3 0l-96-96c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7V32c0-17.7 14.3-32 32-32z"/>
            <path fill="#1e1e1e"
                  d="M124.2 320H48c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H387.8c-12.1 0-23.2 6.8-28.6 17.7l-14.3 28.6c-5.4 10.8-16.5 17.7-28.6 17.7H195.8c-12.1 0-23.2-6.8-28.6-17.7l-14.3-28.6c-5.4-10.8-16.5-17.7-28.6-17.7z"/>
        </svg>
    );
};

export default ReturnIcon;
