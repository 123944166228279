import React from 'react';

interface IProps {
    size: number;
}

const LegacyIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#ff5a27"
                  d="M171.3 180.7c3-6.3 4.7-13.3 4.7-20.7c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48c7.4 0 14.4-1.7 20.7-4.7L208 262.6v44.1c-18.6 6.6-32 24.4-32 45.3c0 26.5 21.5 48 48 48s48-21.5 48-48c0-20.9-13.4-38.7-32-45.3V262.6l59.3-59.3c6.3 3 13.3 4.7 20.7 4.7c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48c0 7.4 1.7 14.4 4.7 20.7L224 233.4l-52.7-52.7z"/>
            <path fill="#1e1e1e"
                  d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM172.1 226.7L208 262.6v11c-36.5 7.4-64 39.7-64 78.4c0 44.2 35.8 80 80 80s80-35.8 80-80c0-38.7-27.5-71-64-78.4v-11l35.9-35.9c12.6 8.4 27.8 13.3 44.1 13.3c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80c0 16.3 4.9 31.5 13.3 44.1L224 233.4l-29.3-29.3c8.4-12.6 13.3-27.8 13.3-44.1c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80c16.3 0 31.5-4.9 44.1-13.3z"/>
        </svg>
    );
};

export default LegacyIcon;
