import React from 'react';

interface IProps {
    size: number;
}

const C360Icon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#1e1e1e"
                  d="M0 96C0 78.3 14.3 64 32 64H160c12.1 0 23.2 6.8 28.6 17.7s4.3 23.8-3 33.5l-63.7 84.9C163 216.7 192 257 192 304v48c0 53-43 96-96 96H90.5c-25.5 0-49.9-10.1-67.9-28.1L9.4 406.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l13.3 13.3c6 6 14.1 9.4 22.6 9.4H96c17.7 0 32-14.3 32-32V304c0-26.5-21.5-48-48-48H64c-12.1 0-23.2-6.8-28.6-17.7s-4.3-23.8 3-33.5L96 128H32C14.3 128 0 113.7 0 96zm496 32c-8.8 0-16 7.2-16 16V368c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8.8-7.2-16-16-16zm0 320c-44.2 0-80-35.8-80-80V144c0-44.2 35.8-80 80-80s80 35.8 80 80V368c0 44.2-35.8 80-80 80zM320 128c-17.7 0-32 14.3-32 32v33.6c5.2-1 10.5-1.6 16-1.6c44.2 0 80 35.8 80 80v96c0 44.2-35.8 80-80 80s-80-35.8-80-80V288.2c0-.1 0-.2 0-.2V160c0-53 43-96 96-96c17.7 0 32 14.3 32 32s-14.3 32-32 32zM288 272v96c0 8.8 7.2 16 16 16s16-7.2 16-16V272c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/>
            <path fill="#ff5a27"
                  d="M576 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
        </svg>
    );
};

export default C360Icon;
