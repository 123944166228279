import React from 'react';

interface IProps {
    size: number;
}

const WarehouseIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#ff5a27"
                  d="M96 0C78.3 0 64 14.3 64 32V224c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H96zM416 64c-17.7 0-32 14.3-32 32V224c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H416z"/>
            <path fill="#1e1e1e"
                  d="M32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H64v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H96 320 544h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H576V384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H544 320 96 32zm96 64H288v64H128V384zm224 0H512v64H352V384z"/>
        </svg>
    );
};

export default WarehouseIcon;
