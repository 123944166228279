import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalContextProvider } from 'store/GlobalContext';

import AppContainer from './AppContainer';

const App: React.FC = () => {
  return (
    <Router>
      <GlobalContextProvider>
        <AppContainer />
      </GlobalContextProvider>
    </Router>
  );
};

export default App;
