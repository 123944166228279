import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommissionView, { IBootstrapAppProps } from '@vestiaire/rule-engine-frontend/package/bootstrap-app';

import { AuthenticationService } from 'services/authentication.service';
import useTitle from 'utils/useTitle';

import './Commission.scss';
import {useEnvironment} from "utils/useEnvironment";

const Commission: React.FC = () => {
  const { i18n } = useTranslation();
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const auth = new AuthenticationService();
  const environment = useEnvironment();

  const accessToken = auth.getAccessToken();

  useTitle(i18n.t('TOOLS.COMMISSION.TITLE'), true);

  useEffect(() => {
    if (accessToken) {
      setIsUserAuthorized(true);
    }
  }, [accessToken]);

  const commissionConfig = useMemo<IBootstrapAppProps>(() => {
    const baseConfig: IBootstrapAppProps = {
      browserRouterProps: {
        basename: '/commission'
      }
    };

    switch(environment.env) {
      case 'dev':
        return {
          ...baseConfig,
          axiosConfig: {
            baseURL: 'http://localhost:4000/api/v1',
          }
        }
      case 'stage':
      case 'production':
      default:
        return {
          ...baseConfig,
          axiosConfig: {
            baseURL: `${environment.apiBaseUrl}/v1/commission`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        }

    }
  }, [accessToken, environment.apiBaseUrl, environment.env]);

  return (
    <div id="commissionPage">
      {isUserAuthorized ?
        <div className="commissionPage-wrapper">
          <CommissionView {...commissionConfig} />
        </div>
      : <div className="return-tool__not-authorized">
          <div className="return-tool__not-authorized__inner">
            <h2 className="vc-title-m">Authorization failed.</h2>
            <p>You are not logged in or you are not allowed to access this tool. Please try the following:</p>
            <ul>
              <li>Click the login button to try again with different credentials.</li>
              <li>Contact the web site administrator if you believe you should be able to access this tool.</li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
};

export default Commission;
