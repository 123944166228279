import {environments, globalEnv, IEnvironment, IEnvironmentConfig} from "./model";

export default function getEnvironment(supportsTeod = true) {
  const env: string | null = process.env.REACT_APP_ENVIRONMENT || null;
  const teodName: string | null = process.env.REACT_APP_TEOD_NAME || null;

  if (!env || !environments.has(env)) {
    process.exit();
  }

  const config = environments.get(env) as IEnvironmentConfig;
  const environment = {...config, ...globalEnv} as IEnvironment;

  if (env === 'teod') {
    environment.teod = teodName;
  }

  if (!supportsTeod && env === 'teod') {
    environment.env = 'stage'
  }

  return environment;
}
