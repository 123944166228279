import { GlobalState } from './GlobalState';
import { ActionType, GlobalActions } from './GlobalAction';

export function globalReducer(
  state: GlobalState,
  action: GlobalActions
): GlobalState {
  switch (action.type) {
    case ActionType.ChangePageTitle:
      return {
        ...state,
        pageTitle: action.pageTitle,
      };
    case ActionType.ChangeUserLang:
      return {
        ...state,
        userLang: action.userLang,
      };
    case ActionType.ChangeUserTheme:
      return {
        ...state,
        userTheme: action.userTheme,
      };
    case ActionType.DisplaySnackbarMsg:
      return {
        ...state,
        snackbarMsg: action.snackbarMsg,
        snackbarStatus: action.snackbarStatus,
      };
    case ActionType.ResetSnackbar:
      return {
        ...state,
        snackbarMsg: null,
        snackbarStatus: null,
      };
    case ActionType.ShowHeader:
      return {
        ...state,
        showHeader: action.showHeader,
      };
    default:
      throw new Error('Action not supported');
  }
}
