import React from 'react';

interface IProps {
  size: number;
}

const DiscoXpIcon = ({size}:IProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width={size} height={size}
         style={{
           padding: '10px',
           background:'#ebecec',
           borderRadius: '5px'
         }}>
      <path d="m27.147 27.855-8-8 .707-.707 8 8-.707.707Z" fill="#1e1e1e"/>
      <path d="M13 4.502a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-9.5 8.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0Z" fill="#1e1e1e"/>
    </svg>
  );
};

export default DiscoXpIcon;
