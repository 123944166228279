import React, {FC} from 'react';

interface Props {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Loading: FC<Props> = ({isLoading, children}: Props) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return <div className="loading">Loading...</div>;
};

export default Loading;
