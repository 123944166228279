import SnackbarStatus from 'utils/SnackbarStatus';

export enum ActionType {
  ChangePageTitle,
  ChangeUserLang,
  ChangeUserTheme,
  DisplaySnackbarMsg,
  ResetSnackbar,
  ShowHeader,
}

type ChangePageTitle = {
  type: ActionType.ChangePageTitle;
  pageTitle: string;
};

type ChangeUserLang = {
  type: ActionType.ChangeUserLang;
  userLang: string;
};

type ChangeUserTheme = {
  type: ActionType.ChangeUserTheme;
  userTheme: string;
};

type DisplaySnackbarMsg = {
  type: ActionType.DisplaySnackbarMsg;
  snackbarMsg: string;
  snackbarStatus: SnackbarStatus;
};

type ResetSnackbar = {
  type: ActionType.ResetSnackbar;
};

type ShowHeader = {
  type: ActionType.ShowHeader;
  showHeader: boolean;
};

export const displaySnackbarMsg = (snackbarMsg: string, snackbarStatus: SnackbarStatus) => ({
  type: ActionType.DisplaySnackbarMsg,
  snackbarMsg,
  snackbarStatus,
});

export const changePageTitle = (pageTitle: string) => ({
  type: ActionType.ChangePageTitle,
  pageTitle,
});

export const changeUserLang = (userLang: string) => ({
  type: ActionType.ChangeUserLang,
  userLang,
});

export const changeUserTheme = (userTheme: string) => ({
  type: ActionType.ChangeUserTheme,
  userTheme,
});

export const resetSnackbar = () => ({
  type: ActionType.ResetSnackbar,
});

export const showHeaderAction = (showHeader: boolean) => ({
  type: ActionType.ShowHeader,
  showHeader,
});

export type GlobalActions = ChangePageTitle | ChangeUserLang | ChangeUserTheme | DisplaySnackbarMsg | ResetSnackbar | ShowHeader;
