import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { VulcainButton } from '@vestiaire/vc-vulcain-component-library';

import { LOCAL_STORAGE_USER_LOCATION } from 'services/authentication.service';
import { displaySnackbarMsg } from 'store/GlobalAction';
import { GlobalContext } from 'store/GlobalContext';
import ROUTES from 'utils/ROUTES';
import SnackbarStatus from 'utils/SnackbarStatus';

import Menu from 'components/organisms/Authentication/Menu';
import { useAuthentication } from 'utils/useAuthentication';

export interface AuthenticationBtnProps {
  isAuthenticated: boolean;
}

const AuthenticationBtn: React.FC<AuthenticationBtnProps> = ({
  isAuthenticated,
}) => {
  const { i18n } = useTranslation();
  const { dispatch } = useContext(GlobalContext);
  const location = useLocation();
  const { auth } = useAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('refreshToken', handleLoginClick);
    return () => {
      window.removeEventListener('refreshToken', handleLoginClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginClick = async () => {
    if (location.pathname === ROUTES.CALLBACK) {
      return;
    }

    try {
      auth.logout();

      localStorage.setItem(
        LOCAL_STORAGE_USER_LOCATION,
        JSON.stringify(location),
      );

      auth.authorize();
    } catch (error) {
      dispatch(
        displaySnackbarMsg(i18n.t('ERROR.FAILED_LOGIN'), SnackbarStatus.ERROR),
      );
    }
  };

  const handleLogout = () => {
    auth.logout();
    navigate(ROUTES.HOME);
  };

  return (
    <>
      {isAuthenticated ? (
        <Menu>
          <VulcainButton
            className="vc-btn--full vc-btn--bad"
            label={i18n.t('AUTH.LOGOUT') as unknown as string}
            name="logout"
            variant="outline"
            onClick={handleLogout}
          />
        </Menu>
      ) : (
        <VulcainButton
          label={i18n.t('AUTH.LOGIN') as unknown as string}
          name="login"
          variant="primary"
          onClick={handleLoginClick}
        />
      )}
    </>
  );
};

export default AuthenticationBtn;
