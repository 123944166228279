import './SubServiceNav.scss';
import { menu, createIcon, findParent } from 'menu';
import { safeText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import RightArrow from 'components/molecules/Icon/RightArrow';
import Route from 'utils/ROUTES';

const SubServiceNav = () => {
  const subServiceList = menu.apps;
  const { i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const parent = findParent(subServiceList, location.pathname);

  if (!parent) {
    return null;
  }

  return (
    <nav
      className={clsx('sub-service-nav', {
        collapsed: !isMenuOpen,
      })}
    >
      {/* <div className="sub-service-nav__title">
        {isMenuOpen && safeText(i18n.t(`TOOLS.${parent.key}.TITLE`))}
      </div> */}
      <div className="sub-service-nav__title">
        <Link to={Route.HOME}>
          {isMenuOpen && safeText(i18n.t(`TOOLS.${parent.key}.TITLE`))}
        </Link>
      </div>

      <button
        className="collapsed-btn"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <RightArrow size={24} />
      </button>

      <ul className="sub-service-nav__list">
        {(parent?.children || []).map(item => (
          <li
            key={item.key}
            className="sub-service-nav__list__item"
          >
            <a
              href={item.link}
              className="sub-service-nav__list__item__link vc-text"
            >
              {createIcon(item.icon, 35)}
              {isMenuOpen && (
                <span>{safeText(i18n.t(`TOOLS.${item.key}.TITLE`))}</span>
              )}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SubServiceNav;
