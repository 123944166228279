import MinitoolLayout from '../MinitoolLayout/Minitool';
import { ManualShipingLabel } from '@vestiaire/vc-backoffice-mini-apps';

const ManualShippingLabel = () => {
  return (
    <MinitoolLayout
      Component={ManualShipingLabel}
      translationKey="SHIPPING_LABEL"
      containerStyle={{ overflow: 'auto', minHeight: '100vh' }}
    />
  );
};

export default ManualShippingLabel;
