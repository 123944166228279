import React from 'react';

interface IProps {
    size: number;
}

const RuleEngineIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#1e1e1e"
                  d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM224 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V336zM432 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H432c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48z"/>
            <path fill="#ff5a27"
                  d="M144 224l80.3 107c2.5-24.2 22.9-43 47.7-43L191.7 181c-2.5 24.2-22.9 43-47.7 43zm48-64H384V96H192v64z"/>
        </svg>
    );
};

export default RuleEngineIcon;
