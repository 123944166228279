import React from 'react';

interface IProps {
  size: number;
}

const RightIcon = ({ size = 24 }: IProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M9 18l6-6-6-6"></path>
    </svg>
  );
};

export default RightIcon;
