import React from 'react';

interface IProps {
    size: number;
}

const ProIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#ff5a27"
                  d="M80 184v80 64c0 13.3 10.7 24 24 24s24-10.7 24-24V288h32c35.3 0 64-28.7 64-64s-28.7-64-64-64H104c-13.3 0-24 10.7-24 24zm48 56V208h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H128zm112-56v80 64c0 13.3 10.7 24 24 24s24-10.7 24-24V288h18.7l33 52.7c7 11.2 21.8 14.7 33.1 7.6s14.7-21.8 7.6-33.1l-24.1-38.5C373 265.2 384 245.9 384 224c0-35.3-28.7-64-64-64H264c-13.3 0-24 10.7-24 24zm48 56V208h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H288zm200-80c-48.6 0-88 39.4-88 88v16c0 48.6 39.4 88 88 88s88-39.4 88-88V248c0-48.6-39.4-88-88-88zm-40 88c0-22.1 17.9-40 40-40s40 17.9 40 40v16c0 22.1-17.9 40-40 40s-40-17.9-40-40V248z"/>
            <path fill="#1e1e1e"
                  d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm40 128h56c35.3 0 64 28.7 64 64s-28.7 64-64 64H128v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V264 184c0-13.3 10.7-24 24-24zm56 80c8.8 0 16-7.2 16-16s-7.2-16-16-16H128v32h32zm80-56c0-13.3 10.7-24 24-24h56c35.3 0 64 28.7 64 64c0 21.9-11 41.2-27.7 52.7l24.1 38.5c7 11.2 3.6 26-7.6 33.1s-26 3.6-33.1-7.6l-33-52.7H288v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V264 184zm48 56h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H288v32zm200-80c48.6 0 88 39.4 88 88v16c0 48.6-39.4 88-88 88s-88-39.4-88-88V248c0-48.6 39.4-88 88-88zm-40 88v16c0 22.1 17.9 40 40 40s40-17.9 40-40V248c0-22.1-17.9-40-40-40s-40 17.9-40 40z"/>
        </svg>
    );
};

export default ProIcon;
