import React from 'react';

interface IProps {
    size: number;
}

const CurationIcon = ({ size }: IProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={size} height={size}
             style={{
                 padding: '10px',
                 background:'#ebecec',
                 borderRadius: '5px'
             }}>
            <path fill="#1e1e1e"
                  d="M409.4 281.4L294.6 166.6c-4.2-4.2-10-6.6-16-6.6c-12.5 0-22.6 10.1-22.6 22.6v29.1L364.3 320h29.1c12.5 0 22.6-10.1 22.6-22.6c0-6-2.4-11.8-6.6-16zm-68.3 72L222.6 234.9c-42.7-3.7-85.2 11.7-115.8 42.3l-8 8C76.5 307.5 64 337.7 64 369.2c0 6.8 7.1 11.2 13.2 8.2l29.9-15c8.3-4.2 15.9 6.9 8.9 13.1L7.3 473.4C2.7 477.6 0 483.6 0 489.9C0 502.1 9.9 512 22.1 512l173.3 0c38.8 0 75.9-15.4 103.4-42.8c30.6-30.6 45.9-73.1 42.3-115.8z"/>
            <path fill="#ff5a27"
                  d="M566.6 9.4c12.5 12.5 12.5 32.8 0 45.3l-192 192-45.3-45.3 192-192c12.5-12.5 32.8-12.5 45.3 0z"/>
        </svg>
    );
};

export default CurationIcon;
