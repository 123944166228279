import dev from "./environment.dev";
import stage from "./environment.stage";
import teod from "./environment.teod";
import production from "./environment.production";

export interface IEnvironmentConfig {
  env: string;
  teod?: string | null;
  logLevel: string;
  apiBaseUrl: string;
  atlasBaseUrl: string;
  imagesBaseUrl: string;
  cookiesDomain: string;
  oauthUrl: string;
  oauthClientCallbackUrl: string;
  oauthClientId: string;
  oauthClientScopes: string;
  toolAuth: string;
}

export interface IGlobalConfig {
  i18n: {
    fallback: string,
    locale: string,
    i18nCookie: string,
    supportedLang: Array<string>
  },
  theme: {
    default: string,
    themeCookie: string,
    supportedTheme: Array<string>
  }
}

export interface IEnvironment extends IEnvironmentConfig, IGlobalConfig{}
export interface IEnvironments extends Map<string, IEnvironmentConfig> {}

export const environments: IEnvironments = new Map([
  ['dev', dev],
  ['stage', stage],
  ['teod', teod],
  ['production', production],
]);

export const globalEnv = {
  i18n: {
    fallback: 'en',
    locale: 'us',
    i18nCookie: 'vc_bo_lng',
    supportedLang: ['en', 'fr']
  },
  theme: {
    default: 'light',
    themeCookie: 'vc_bo_theme',
    supportedTheme: ['light', 'medium', 'dark']
  }
};
