import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IWCConfig } from 'services/types';
import { GlobalContext } from 'store/GlobalContext';
import { useAuthentication } from 'utils/useAuthentication';
import { useEnvironment } from 'utils/useEnvironment';
import useTitle from 'utils/useTitle';

import './Minitool.scss';

interface Props {
  Component: React.FC<any>;
  translationKey: string;
  containerStyle?: React.CSSProperties;
}

interface IVCUser {
  firstName: string;
  lastName: string;
}

const MinitoolLayout: React.FC<Props> = props => {
  const { i18n } = useTranslation();
  const { Component, translationKey, containerStyle } = props;
  const { accessToken, idToken } = useAuthentication();
  const environment = useEnvironment();

  // GLOBAL STATE
  const {
    state: { userLang, userTheme },
  } = useContext(GlobalContext);

  // LOCAL STATE
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [config, setConfig] = useState<IWCConfig>();
  const [vcUser, setVcUser] = useState<IVCUser>();

  useTitle(i18n.t(`TOOLS.${translationKey}.TITLE`), true);

  useEffect(() => {
    setConfig({
      agentEmail: idToken?.email || '',
      env: environment.env,
      lang: userLang,
      theme: userTheme,
    });
    setVcUser({
      firstName: idToken?.given_name || '',
      lastName: idToken?.family_name || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessToken) {
      setIsUserAuthorized(true);
    }
  }, [accessToken]);

  return (
    <div
      id="layout-tool"
      className="layout-tool"
      style={containerStyle}
    >
      {isUserAuthorized && config && vcUser ? (
        <Component
          wcconfig={config}
          vcuser={vcUser}
        />
      ) : (
        <div className="qualityControl-tool__not-authorized">
          <div className="qualityControl-tool__not-authorized__inner">
            <h2 className="vc-title-m">Authorization failed.</h2>
            <p>
              You are not logged in or you are not allowed to access this tool.
              Please try the following:
            </p>
            <ul>
              <li>
                Click the login button to try again with different credentials.
              </li>
              <li>
                Contact the web site administrator if you believe you should be
                able to access this tool.
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MinitoolLayout;
