import i18n from 'i18next';
import Cookies from 'cookies-ts';
import {initReactI18next} from 'react-i18next';

import us from 'i18n/us.json';
import en from 'i18n/en.json';
import fr from 'i18n/fr.json';
import de from 'i18n/de.json';
import it from 'i18n/it.json';
import es from 'i18n/es.json';
import getEnvironment from "./environments";

const Locales = { de, en, es, fr, it, us };

const $cookies = new Cookies();

const resources = Object.keys(Locales).reduce((prev, curr) => {
  (prev as any)[curr] = { translation: (Locales as any)[curr] };
  return prev;
}, {});
const environment = getEnvironment();

i18n
  .use(initReactI18next)
  .init({
    lng: $cookies.isKey(environment.i18n.i18nCookie)
      ? $cookies.get(environment.i18n.i18nCookie) as string
      : environment.i18n.locale,
    fallbackLng: environment.i18n.fallback || 'us',
    load: 'languageOnly',
    resources,
    keySeparator: '.',
    nsSeparator: false,
  });

export default i18n;
