import React from 'react';

interface IProps {
  size: number;
}

const ProductActionIcon = ({ size }: IProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width={size}
      height={size}
      style={{
        padding: '10px',
        background: '#ebecec',
        borderRadius: '5px',
      }}
    >
      <path
        fill="#ff5a27"
        d="M240.9 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H267.1l-5.7 34c.8 1 1.5 2 2.3 3l204.5 27.3c17.5 2.3 29.8 18.4 27.5 35.9c-1.4 10.2-7.4 18.7-15.7 23.5V208c0 8.8-7.2 16-16 16s-16-7.2-16-16V190.1L255.2 164.5c-8.4 7.2-19.3 11.6-31.2 11.6c-26.5 0-48-21.5-48-48c0-17.1 8.9-32 22.3-40.6L202.2 64H160c-17.7 0-32-14.3-32-32s14.3-32 32-32h79c.6 0 1.2 0 1.9 0zM240 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM96 280c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v40H96V280zm184-24h48c13.3 0 24 10.7 24 24v40H256V280c0-13.3 10.7-24 24-24zm160 0h48c13.3 0 24 10.7 24 24v40H416V280c0-13.3 10.7-24 24-24z"
      />
      <path
        fill="#1e1e1e"
        d="M96 320c-53 0-96 43-96 96s43 96 96 96H544c53 0 96-43 96-96s-43-96-96-96H96zm32 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm160 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm224-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      />
    </svg>
  );
};

export default ProductActionIcon;
