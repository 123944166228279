enum ROUTES {
  HOME = '/',
  CALLBACK = '/auth/callback',
  PREFERENCES = '/user/preferences',
  // group service
  WAREHOUSE_SERVICE = '/warehouse-service',
  TRANSPORT_SERVICE = '/transport-service',
  SWITCH_PICKUP = '/switch-pu',
  SHIPPING_LABEL = '/shipping-label',
  BULK_IMPORT = '/bulk-import',

  // TOOLS URI
  COMMISSION = '/commission',
  QC = '/quality-control',
  RETURN = '/return',
  C360 = '/c360',
  WAREHOUSE = '/warehouse',
  SHIPPING = '/shipping',
  PRODUCTACTION = '/product-action',
  CURATION = '/curation',
  B2CSELFSERVICE = '/b2c-self-service',
  PAYMENTSERVICE = '/payment-service',
  DISCO_XP_SERVICE = '/disco-xp-service',
}

export default ROUTES;
